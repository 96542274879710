.content {
    /*display: flex;*/
    overflow-y:scroll;
    overflow-x:hidden;
    display: block;
    height: 90vh;
    width: 90%;
    position: absolute;
    padding-left: 35px;
    padding-right: 20px;
    font-size: 12px;
    color: black;
}

.contentbox {
    font-size: 16px;
    hyphens: auto;
    text-align: justify;
}

.caption {
    text-align: left;
}


.button-container {
    display: flex;
    justify-content: right;
    flex-direction: column-reverse;
}

.content-button{
    align-self: flex-end;
    height: 10%;
    padding: 10px;
    margin: 10px;
    font-size: 14px;
    background: #ff9100;
    color: white;
    text-align: center;
    border-radius: 7px;
    box-shadow: 0 0 10px -5px black;
    border: none;
}

.content-button:hover{
    background: #f38002;
    cursor: pointer;
}

.content-button:focus{
    background: #f34602;
}

.content-button:active{
    background: #f34602;
}


.rounded-rect {
    background: white;
    opacity: 0.8;
    border-radius: 7px;
    box-shadow: 0 0 50px -25px black;
}

.flex-center {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-center.left {
    left: 0px;
}

.flex-center.right {
    right: 0px;
}

.sidebar-content {
    opacity: 1;
    position: absolute;
    width: 95%;
    height: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 32px;
    color: gray;
}

.sidebar-toggle {
    position: absolute;
    width: 0.4em;
    height: 0.9em;
    padding-bottom: 5px;
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar-toggle.left {
    font-size: 1.6em;
    right: -0.5em;
}

.sidebar-toggle.right {
    font-size: 1.6em;
    left: -0.5em;
}

.sidebar-toggle:hover {
    color: #0aa1cf;
    cursor: pointer;
}

.leftsidebar {
    transition: transform 1s;
    z-index: 10;
    width: 500px;
    height: 90vh;
}

.rightsidebar {
    transition: transform 1s;
    z-index: 10;
    width: 500px;
    height: 90vh;
}


/*
The sidebar styling has them "expanded" by default, we use CSS transforms to push them offscreen
The toggleSidebar() function removes this class from the element in order to expand it.
*/
.left.collapsed {
    transform: translateX(-494px);
}

.right.collapsed {
    transform: translateX(495px);
}
