.break {
    flex-basis: 100%;
    height: 0;
}

.draw-toolbar{
    position: absolute;
    top: 20px;
    left: 30%;
    display: flex;
    flex-direction: row;
    background-color: white;
    padding-bottom: 18px;
    font-family: Arial;
}

.orangeButton{
    /*align-self: flex-end;*/
    margin: 10px;
    height: 18px;
    padding: 7px;
    font-size: 14px;
    background: #ff9100;
    color: white;
    cursor: pointer;
    text-align: center;
    border-radius: 7px;
    box-shadow: 0 0 10px -5px black;
    border: none;
}

.orangeButton2{
    /*align-self: flex-end;*/
    margin: 10px;
    height: 30px;
    padding: 7px;
    font-size: 14px;
    background: #ff9100;
    color: white;
    cursor: pointer;
    text-align: center;
    border-radius: 7px;
    box-shadow: 0 0 10px -5px black;
    border: none;
}


.submitfield{
    height: 30px;
    /*position: absolute;*/
    /*top: 20px;*/
    /*left: 90%;*/
    /*width: 10px;*/
}


.datagrid{
    position: fixed;
    bottom: 25px;
    width: 99vw;
    font-family: Helvetica;
    font-size: 0.8em;
}
