.react-dat-gui {
    width: 320px;
    left: 50px;
    top: 0px;
    z-index: 2 !important;
}

.quickTour:hover{
    color: #ffcece;
}

.title:focus {
    outline: -webkit-focus-ring-color auto 0px;
}

.openMenu .label-text {
    padding: 1px 1px 1px 1px !important;
}

.closeMenu .label-text {
    padding: 1px 1px 1px 1px !important;
}
