.BarchartContainer {
    position: absolute;
    bottom: 5vh;
    left: calc(18vw + 10px);
    width: 60vw;
    /*background-color: orangered;*/
    /*opacity: 0.5;*/
    /*margin: 20vh;*/
    height: 12vh;
    padding: 10px -10px 10px 10px;
    font-family: Lucida Grande,sans-serif;
}

.SingleBar {
    position: absolute;
    bottom: 1.2vh;
    background-color: #4682B4AA;
    opacity: 1;
    width: 1.3em;
    height: 12vh;
    /*z-index: 100000;*/
}
