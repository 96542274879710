body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.onboardingcontainer {
  position: fixed;
  display: flex;
  height: auto;
  flex-direction: column;
  /*border: 1px solid black;*/
  background-color: #4A4B59DD;
  color: white;
  z-index: 300;
  padding: 0.8vmax;
  border-radius: 10px;
  font-size: 1em;
  pointer-events: none;
}

.Xbuttonright {
  /*background-color: lightgray;*/
  /*border: 1px solid darkgrey;*/
  color: white;
  /*border-radius: 5px;*/
  padding: 0px;
  width: auto;
  margin-left: auto;
  pointer-events: auto;
}

.Xbuttonright:hover {
  /*background-color: white;*/
  cursor: pointer;
}


.onboardingText p {
  padding: 0px;
  margin: 0.5em;
  max-width: 40vw;
}

.triangle-down {
  position: fixed;
  width: 1vw;
  height: 30px;
  pointer-events: none;
  border-left: 8px solid transparent;
  border-right: 30px solid transparent;
  /*border-top: 2vh solid red;*/
  z-index: 3;
}

