.mapboxgl-ctrl-top-left {
    top: 60px;
}

.mapboxgl-ctrl-top-right {
    top: 60px;
}

.fulloverlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #111111dd;
    z-index: 150;
    color: white;
    overflow-y: scroll;
}

.closeButton {
    position: fixed;
    top: 2%;
    left: 96%;
    color: white;
    font-weight: 600;
    font-size: 1.3em;
    cursor: pointer;
}

.closeButton:hover {
    font-weight: 900;
    color: lightgray;
}

.contentContainer {
    padding: 5% 25% 5% 25%;
}

@media (max-width: 12500px) {
    .contentContainer {
        padding: 5% 35% 5% 35%;
    }
}


@media (max-width: 13000px) {
    .contentContainer {
        padding: 5% 40% 5% 40%;
    }
}

@media (max-width: 2000px) {
    .contentContainer {
        padding: 5% 35% 5% 35%;
    }
}

@media (max-width: 1600px) {
    .contentContainer {
        padding: 5% 30% 5% 30%;
    }
}

@media (max-width: 1024px) {
    .contentContainer {
        padding: 5% 20% 5% 20%;
    }
}

@media (max-width: 800px) {
    .contentContainer {
        padding: 5% 10% 5% 10%;
    }
}


.controlHeader {
    position: fixed;
    top: 0;
    width: 100%;
    height: 55px;
    /*color: white;*/
    color: #141414;
    /*background-color: #666666EE;*/
    background-color: #d9dee2;
    /*background-color: #bbb;*/
    box-shadow: 0 0 20px #FFFFFF;
}

.logo {
    margin-left: 5px;
    margin-top: 2px;
}

.controlContainer {
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    left: 120px;
    top: 2px;
    /*color: white;*/
    /*background: white;*/
    /*width: 300px;*/
    font-family: 'Crete Round', Times, serif;
    font-size: 0.9em;
    margin: 15px;
    /*padding-left: 20px;*/
    /*padding: 5px;*/
}

.navlink{
    padding-left: 30px;
    cursor: pointer;
    flex: 1;
    white-space: nowrap;
}

.navlink:hover {
    color: #000;
    font-weight: 600;
}

.navlink1{
    padding-left: 10px;
    cursor: pointer;
    flex: 1;
}

.navlink1:hover {
    color: #000;
    font-weight: 600;
}


.navlink2{
    padding-right: 10px;
    width: 10em;
    white-space: nowrap;
    cursor: pointer;
    flex: 1;
}

.navlink2:hover {
    color: #000;
    font-weight: 600;
}

.navsliderContainer{
    /*display: flex;*/
    /*flex-flow: row nowrap;*/
    /*align-items: baseline;*/
    /*width: 80px;*/
    /*padding-left: 30px;*/
    cursor: pointer;
    /*flex: 1;*/
}

.contentbox a:any-link{
    color: white;
}


.controlButton {
    /*font-family:'Crete Round', sans-serif;*/
    font-weight: bold;
    font-size: 0.9em;
    flex-shrink: 0;
    background-color: #ffd386;
    /*color: white;*/
    width: auto;
    /*align-self: flex-end;*/
    /*padding: 3px;*/
    border-radius: 5px;
    margin: 5px;
    cursor: auto;
    box-shadow: 4px 4px 5px grey;
}

.controlButton :hover {
    cursor: pointer;
}

.controlSelect {
    margin: 5px;
    /*background-color: #d9dee2;*/
    background-color: inherit;
    /*font-family: 'Crete Round', Times, serif;*/
    font-family: inherit;
    flex: 1;
}

.legendContainer {
    position: fixed;
    display: flex;
    flex-direction: column;
    left: 10px;
    bottom: 10%;
    background: #ffffff99;
    width: 250px;
    font-family: Arial;
    font-size: 0.7em;
}

.legendBox{
    width: 20px;
    margin: 5px;
    border: 1px solid darkgray;
    /*background-color: chocolate;*/
}

.legendCircle{
    width: 10px;
    height: 10px;
    margin: 5px;
    /*background-color: #88888800;*/
    border: 2px solid steelblue;
    border-radius: 20px;
    /*background-color: chocolate;*/
}

.legendItem{
    width: 220px;
    padding-left: 40px;
}

.rc-slider-handle-1 {
    background-color: grey;
}

/*.rc-slider-handle-1:hover {*/
/*    border-color: black;*/
/*    pointer-events: none;*/
/*}*/
